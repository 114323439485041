import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";

import App from "@/App.vue";
import router from "@/router";
import "./application.css";

document.addEventListener("DOMContentLoaded", () => {
  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);
  const app = createApp(App);
  app.use(pinia).use(router).use(ToastPlugin).mount("#app");
});
