import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import TokenExplain from '@/views/TokenExplainView.vue'
import { useUserStore } from '@/stores/user'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/rechargeList',
      name: 'rechargeList',
      component: () => import('@/views/RechargeListView.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/tokenExplain',
      name: 'TokenExplain',
      component: TokenExplain
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/About.vue')
    },
    {
      path: '/customization',
      meta: { title: '定制人工智能算力' },
      component: () => import('@/views/Customization.vue')
    },
    // h5
    {
      path: '/h5',
      component: () => import('@/h5/views/index.vue')
    },
    {
      path: '/h5/rechargeList',
      meta: { title: '充值记录', requiresAuth: true },
      component: () => import('@/h5/views/RechargeListView.vue')
    },
    {
      path: '/h5/pay',
      meta: { title: '账户充值', requiresAuth: true },
      component: () => import('@/h5/views/payView.vue')
    },
    {
      path: '/h5/paySuccess',
      meta: { title: '支付成功', requiresAuth: true },
      component: () => import('@/h5/views/paySuccessView.vue')
    },
    {
      path: '/h5/about',
      meta: { title: '关于我们' },
      component: () => import('@/h5/views/about.vue')
    }
  ]
})

router.beforeEach((to, from) => {
  const userStore = useUserStore()
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.requiresAuth && !userStore.token) {
    if (to.path.match(/\/h5/)) {
      return {
        path: '/h5',
        query: { redirect: to.fullPath, showLogin: true }
      }
    } else {
      return {
        path: '/',
        query: { redirect: to.fullPath, showLogin: true }
      }
    }
  }
})

export default router
