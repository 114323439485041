<template>
  <div class="bg-white w-full h-20 flex items-center">
    <router-link to="/" class="ml-10 mr-auto flex items-center">
      <div class="h-[40px] w-[218px] bg-[url('@/images/logo.png')] bg-cover cursor-pointer"></div>
      <router-link to="/customization" class="px-3 py-2 ml-10 bg-blue text-white rounded-lg">定制人工智能算力</router-link>
    </router-link>
    <div class="mr-[120px] flex items-center gap-20">
      <div @click="showPayModal" class="cursor-pointer">账户充值</div>
      <div @click="copyEmail" class="cursor-pointer">联系客服</div>
      <router-link to="/rechargeList" class="cursor-pointer">充值记录</router-link>
      <router-link to="/tokenExplain" class="cursor-pointer">计费规则</router-link>
      <router-link to="/about" class="cursor-pointer">关于我们</router-link>
    </div>
    <div v-if="!userStore.token" @click="display.showLogin = true" class="cursor-pointer">登录/注册</div>
    <div v-else @mouseover="display.isHovering = true" @mouseleave="display.isHovering = false" class="h-full flex items-center relative cursor-pointer">
      {{ userStore.info.phone }}
      <userInfo v-if="display.isHovering" class="absolute top-[90px] right-[-60px]" />
    </div>
    <div class="h-10 w-10 ml-5 mr-10 bg-[url('@/images/avatar-icon.png')] bg-cover"></div>
  </div>

  <loginModal v-model:show="display.showLogin" />
  <payModal v-model:show="display.showPay" />
</template>

<script setup>
import { reactive } from 'vue'
import { useUserStore } from '@/stores/user'
import userInfo from '@/components/userInfo.vue'
import loginModal from '@/components/loginModal.vue'
import payModal from '@/components/payModal.vue'
import { useToast } from 'vue-toast-notification'

const $toast = useToast()
const userStore = useUserStore()

const display = reactive({
  showLogin: false,
  showPay: false,
  isHovering: false
})

const showPayModal = () => {
  if (userStore.token) {
    display.showPay = true
  } else {
    display.showLogin = true
  }
}

const copyEmail = () => {
  const email = 'support@bestapp.chat'
  navigator.clipboard.writeText(email)
  $toast.success(`已复制客服邮箱 ${email}`)
}
</script>
