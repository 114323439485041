<template>
  <div class="w-[216px] bg-white pt-5 px-5 rounded-2xl">
    <div class="mb-6 flex items-center">
      <div class="mr-3 w-[36px] h-[36px] bg-[url('@/images/phone-icon.png')] bg-contain bg-no-repeat"></div>
      <div>
        <div class="text-sm text-[#B1B1B1]">手机号</div>
        <div class="text-black">{{ store.info.phone }}</div>
      </div>
    </div>
    <div class="mb-6 flex items-center">
      <div class="mr-3 w-[36px] h-[36px] bg-[url('@/images/token-icon.png')] bg-contain bg-no-repeat"></div>
      <div>
        <div class="text-sm text-[#B1B1B1]">剩余token</div>
        <div class="text-black">{{ formatRemainTokens }}</div>
      </div>
    </div>
    <router-link to="/rechargeList" class="mb-6 flex items-center">
      <div class="mr-3 w-[36px] h-[36px] bg-[url('@/images/balance-icon.png')] bg-contain bg-no-repeat"></div>
      <div>
        <div class="text-sm text-[#B1B1B1]">充值记录</div>
      </div>
    </router-link>
    <div
      class="mx-auto w-[176px] h-[55px] border-t border-[#EDEFF2] flex items-center justify-center cursor-pointer"
      @click="logout"
    >
      <div class="mr-1 text-black">退出登录</div>
      <div class="w-[17px] h-[16px] bg-[url('@/images/logout-icon.png')] bg-contain bg-no-repeat"></div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import userApi from "@/api/user";
import { useUserStore } from "@/stores/user";

const store = useUserStore();

const logout = () => {
  localStorage.removeItem("token");
  store.$patch({ token: "", info: {} });
};

onMounted(() => {
  userApi.info().then((res) => {
    store.$patch({ info: res.data });
  });
});

const formatRemainTokens = computed(() => {
  return store.info.remain_tokens < 10000
    ? store.info.remain_tokens
    : `${(store.info.remain_tokens / 10000).toFixed(2)}万`;
});
</script>
