import axios from "axios";
import { useUserStore } from "@/stores/user";
import { useToast } from "vue-toast-notification";

const $toast = useToast();

const instance = axios.create({
  baseURL: "/api/v1",
  timeout: 5000,
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    /* 添加token */
    let token = localStorage.getItem("token") || "";
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response;
  },
  (error) => {
    // 处理响应错误
    if (/40[0-9]/.test(error.response.status)) {
      if (screen.width <= 500) {
        $toast.error(error.response.data.message, {
          position: 'top'
        })
      } else {
        $toast.error(error.response.data.message);
      }
    }
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      const userStore = useUserStore();
      userStore.$patch({ token: "", info: {} });
    }
    return Promise.reject(error);
  }
);

export default instance;
