<template>
  <div>
    <navBar />
    <div class="w-[475px] h-[48px] mt-[100px] mx-auto bg-[url('@/images/logo-black.svg')] bg-cover"></div>
    <div class="mt-[90px] mx-auto flex justify-center gap-8">
      <div class="flex flex-col items-center" v-for="item in functionList">
        <img class="h-[56px] w-[56px] mb-3" :src="getImageFile(item.image)" />
        <div class="mb-8 text-2xl font-medium">{{ item.functionName }}</div>
        <div class="mb-3 w-[418px] h-20 bg-white rounded-lg flex items-center justify-center" v-for="d in item.desc">
          {{ d }}
        </div>
      </div>
    </div>

    <div class="mx-auto mt-12 w-[571px] h-[88px] bg-[url('@/images/chat-btn.png')] bg-contain bg-no-repeat flex cursor-pointer" @click="openChat">
      <div class="m-auto text-white text-2xl font-medium">与云从Mass聊天吧</div>
    </div>
  </div>
  <loginModal v-model:show="display.showLogin" />
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import { useUserStore } from '@/stores/user'
import loginModal from '@/components/loginModal.vue'
import payModal from '@/components/payModal.vue'
import navBar from '@/components/navBar.vue'
import { useToast } from 'vue-toast-notification'
import { useRouter, useRoute } from 'vue-router'

import util from '@/composables/util'
const getImageFile = util.getImageFile

const $toast = useToast()
const $router = useRouter()
const $route = useRoute()
const userStore = useUserStore()
const display = reactive({
  showLogin: false,
  isHovering: false
})

const functionList = [
  {
    image: 'assistant-icon.png',
    functionName: '工作助手',
    desc: ['文档优化，文档编辑，内容总结', '广告词、创意文案', '辅助决策']
  },
  {
    image: 'manage-icon.png',
    functionName: '管理必备',
    desc: ['提高效率，节省时间和精力', '优化客户服务', '培训和教育']
  },
  {
    image: 'coding-icon.png',
    functionName: '代码编程',
    desc: ['优化客户服务、改善内部沟通', '销售和市场营销支持', '邮件与会议决策']
  }
]

const openChat = () => {
  if (userStore.token) {
    util.redirectToChat()
  } else {
    display.showLogin = true
  }
}

onMounted((option) => {
  if ($route.query.showLogin) {
    display.showLogin = true
  }
})
</script>
