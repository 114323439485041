import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    token: "",
    info: {},
  }),
  getters: {
    // doubleCount: (state) => state.count * 2,
  },
  actions: {
    // increment() {
    //   this.count++;
    // },
  },
  persist: true,
});
