<template>
  <div v-show="show" class="fixed top-0 left-0 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.2)]" @click.stop="emit('update:show', false)">
    <div class="z-100 flex" @click.stop>
      <div class="w-[587px] h-[476px] inline-block bg-[url('@/images/login/bg1.png')] bg-contain bg-no-repeat text-white">
        <div class="pl-7 pt-20 flex items-center">
          <div class="w-[35px] h-[31px] mr-4 bg-[url('@/images/login/vip-icon.png')] bg-contain bg-no-repeat"></div>
          <div class="text-2xl font-medium">VIP专享</div>
        </div>
        <div class="mt-8 ml-7">
          <div class="mb-6 flex items-center" v-for="item in abilityList">
            <div class="w-[18px] h-[18px] mr-3 bg-[url('@/images/login/ability-icon.png')] bg-contain bg-no-repeat"></div>
            <div class="">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="w-[560px] h-[476px] ml-[-386px] inline-block bg-[url('@/images/login/bg2.png')] bg-contain bg-no-repeat relative">
        <div class="absolute top-2 right-2 w-9 h-9 bg-[url('@/images/modal-close.svg')] cursor-pointer" @click.stop="emit('update:show', false)"></div>
        <div class="mt-14 flex items-center justify-center">
          <div class="text-2xl text-black font-semibold">欢迎加入</div>
          <div class="h-[24px] w-[238px] ml-3 bg-[url('@/images/logo.svg')] bg-cover"></div>
        </div>
        <input class="block mt-12 mx-auto w-[360px] h-[56px] p-4 rounded-lg border border-[#E6E8EB]" type="text" placeholder="请输入手机号码" maxlength="11" v-model="form.phone" />
        <captcha v-if="show" class="mt-3 mx-auto w-[360px]" @success="setCaptchaToken" ref="captchaCom"></captcha>
        <div class="relative">
          <input class="block mt-4 mx-auto w-[360px] h-[56px] p-4 rounded-lg border border-[#E6E8EB]" type="text" placeholder="请输入验证码" maxlength="6" v-model="form.smsCode" />
          <div class="absolute top-4 right-[120px] text-blue cursor-pointer" @click="sendSmsCode">
            {{ timer === 0 ? '发送验证码' : `${timer}` }}
          </div>
        </div>
        <div class="mt-12 mx-auto w-[360px] h-[56px] flex items-center justify-center bg-blue rounded-3xl font-medium text-white cursor-pointer" @click="login">登录</div>
        <!-- <div class="mt-8 ml-[100px] flex items-center">
          <div class="h-[15px] w-[15px] mr-3 bg-[url('@/images/login/select-icon.png')] bg-contain bg-no-repeat"></div>
          <div>接受 <span class="text-blue">隐私条款</span> 和 <span class="text-blue">用户协议</span></div>
        </div> -->
      </div>
    </div>
  </div>

  <div v-if="showSignUpSuccess" class="fixed top-0 left-0 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.2)]" @click.stop="showSignUpSuccess = false">
    <div class="z-100 flex items-end" @click.stop>
      <div class="w-[312px] h-[199px] bg-[url('@/images/result-modal-bg.png')] bg-contain bg-no-repeat">
        <div class="mt-[42px] flex justify-center items-center">
          <div class="w-[36px] h-[36px] bg-[url('@/images/success-icon.png')] bg-contain bg-no-repeat"></div>
          <div class="ml-2">
            <div class="font-medium">恭喜，注册成功！</div>
            <div class="text-sm text-[#7A8185]">送您10000个体验token</div>
          </div>
        </div>
        <div class="mt-[26px] mx-auto w-[224px] h-[42px] flex items-center justify-center bg-blue text-white rounded-3xl cursor-pointer" @click="openChat">马上体验</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import userApi from '@/api/user'
import { useUserStore } from '@/stores/user'
import { useToast } from 'vue-toast-notification'
import captcha from '@/components/captcha.vue'
import { useIntervalFn } from '@vueuse/core'

import util from '@/composables/util'

const userStore = useUserStore()
const $toast = useToast()

const prop = defineProps({
  show: Boolean
})
const emit = defineEmits(['update:show'])

const form = reactive({
  phone: '',
  smsCode: '',
  captcha: ''
})
const showSignUpSuccess = ref(false)
const captchaCom = ref(null)
const timer = ref(0)

const abilityList = ['消息不限次数', '消息不限长度', '云从大模型随时体验']

const login = () => {
  if (form.phone.length != 11) {
    return $toast.error('手机号格式错误')
  }
  if (form.smsCode.length != 6) {
    return $toast.error('验证码格式错误')
  }
  userApi.smslogin({ phone: form.phone, sms_code: form.smsCode }).then((res) => {
    localStorage.setItem('token', res.data.token)
    let jwtParts = res.data.token.split('.')
    let payload = JSON.parse(atob(jwtParts[1]))
    userStore.$patch({
      token: res.data.token,
      info: payload
    })
    emit('update:show', false)
    if (userStore.info.new_user) {
      showSignUpSuccess.value = true
    } else {
      $toast.success('登录成功')
    }
  })
}

const sendSmsCode = () => {
  if (timer.value > 0) {
    return
  }
  if (form.phone.length != 11) {
    return $toast.error('手机号格式错误')
  }
  if (!form.captcha) {
    return $toast.error('请先进行验证')
  }
  userApi.sendSmsCode({ phone: form.phone, captcha: form.captcha }).then(
    (_res) => {
      timer.value = 60
      resume()
      $toast.success('已发送验证码')
    },
    (err) => {
      captchaCom.value.reset()
      throw err
    }
  )
}

const setCaptchaToken = (res) => {
  form.captcha = res
}

const { pause, resume, isActive } = useIntervalFn(() => {
  if (timer.value <= 0) {
    pause()
  } else {
    timer.value -= 1
  }
}, 1000)

const openChat = () => {
  util.redirectToChat()
  showSignUpSuccess.value = false
}
</script>
