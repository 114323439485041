import request from "./request";

export default {
  // 获取验证码
  sendSmsCode: (data) => {
    return request.post("/user/sms_code", data);
  },
  // 登录
  smslogin: (data) => {
    return request.post("/user/sms_login", data);
  },
  info: () => {
    return request.get("/user/info");
  },
  rechargeList: (data) => {
    return request.get("/user/recharge_list", { params: data });
  },
};
