<template>
  <div v-if="show" class="fixed top-0 left-0 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.2)]" @click.stop="emit('update:show', false)">
    <div class="z-100 flex items-end" @click.stop>
      <div class="w-[587px] h-[512px] inline-block bg-[url('@/images/pay/bg1.png')] bg-contain bg-no-repeat text-[#AC7112]">
        <div class="pl-9 pt-10 flex items-center">
          <div class="w-[35px] h-[31px] mr-4 bg-[url('@/images/login/vip-icon.png')]"></div>
          <div class="text-2xl font-medium">VIP专享</div>
        </div>
        <div class="mt-12 ml-6">
          <div class="mb-7 flex items-center" v-for="item in abilityList">
            <div class="w-[18px] h-[18px] mr-3 bg-[url('@/images/pay/ability-icon.png')] bg-contain bg-no-repeat"></div>
            <div class="text-sm">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="w-[704px] h-[488px] ml-[-387px] inline-block bg-[url('@/images/pay/bg2.png')] bg-contain bg-no-repeat px-5 pt-6 relative">
        <div class="absolute top-2 right-2 w-9 h-9 bg-[url('@/images/modal-close.svg')] cursor-pointer" @click.stop="emit('update:show', false)"></div>
        <div class="text-lg font-medium">购买按量计费套餐</div>
        <div class="pt-3 flex flex-wrap gap-4">
          <div
            v-for="(item, index) in plans"
            class="w-[120px] h-[122px] pt-4 pl-4 rounded-lg rounded-tr-3xl border cursor-pointer"
            :class="[index == data.planIndex ? 'bg-[#FFF7EA] border-[#C8861E]' : 'bg-white border-[#E6E8EB] ']"
            @click="selectPlan(index)"
          >
            <div class="flex items-center">
              <div :class="[index == data.planIndex ? 'text-[#3D2E16]' : 'text-[#7A8185]']">
                {{ item.name }}
              </div>
              <img v-if="item.extra_image" class="w-[43px] h-[19px] pl-1" :src="getImageFile(item.extra_image)" />
            </div>
            <div v-if="item.period">
              <div class="pt-3 text-[#FF5816] text-2xl">
                <span class="text-sm">¥</span>{{ item.amount }}<span class="text-sm">/{{ item.periodUnit }}</span>
              </div>
              <div class="pt-2 text-xs text-[#9F8E75]">有效期{{ item.period }}</div>
            </div>
            <div v-else>
              <div class="pt-3 text-[#FF5816] text-2xl"><span class="text-sm">¥</span>{{ item.amount }}</div>
              <div class="pt-2 text-xs text-[#9F8E75]">{{ item.tokens }} token</div>
            </div>
          </div>
        </div>
        <div class="w-[664px] h-[63px] mx-auto mt-3 pt-4 px-6 bg-[#F9FAFD] rounded-lg relative">
          <div class="flex items-center">
            <div class="w-[79px] h-[18px] bg-[url('@/images/pay/wx-pay.png')] bg-contain bg-no-repeat mr-auto"></div>
            <div class="text-xs text-[#ADADAD]">
              <span>应付金额：</span>
              <span class="text-[#FF5816]">¥</span>
              <span class="text-2xl text-[#FF5816]">{{ currentPlan.amount }}</span>
            </div>
            <div class="ml-10 w-[100px] h-8 bg-blue rounded-lg text-white flex items-center justify-center cursor-pointer" @click="callPay">确认支付</div>
            <div v-if="data.showPayQrcode" class="w-[110px] h-[110px] bg-white absolute top-[-120px] right-5">
              <qrcode-vue :value="data.qrcode" :size="110" level="H" />
            </div>
          </div>
        </div>
        <div class="pt-3 text-xs text-[#ADADAD]">
          <p>token消耗参考</p>
          <p>1、中文，1000个汉字，大约需消耗1200个token；</p>
          <p>2、English, 1000 tokens is about 750 words;</p>
          <p>3、以上数据是云从官方建议及第三方测试经验总结，一切以每次问答的实际消耗为准。</p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="data.showPaySuccess" class="fixed top-0 left-0 flex h-full w-full items-center justify-center bg-[rgba(0,0,0,0.2)]">
    <div class="z-100 flex items-end">
      <div class="w-[312px] h-[172px] bg-[url('@/images/pay/result-modal-bg.png')] bg-contain bg-no-repeat">
        <div class="mt-[42px] flex justify-center items-center">
          <div class="w-[36px] h-[36px] bg-[url('@/images/success-icon.png')] bg-contain bg-no-repeat"></div>
          <div class="ml-2 font-medium">支付成功！</div>
        </div>
        <div class="mt-[26px] flex justify-center">
          <router-link to="/" class="w-[126px] h-[42px] flex items-center justify-center text-blue rounded-3xl border border-blue cursor-pointer" @click="closeAll">首页</router-link>
          <div class="w-[126px] h-[42px] ml-3 flex items-center justify-center bg-blue text-white rounded-3xl cursor-pointer" @click="closeAll">返回</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue'
import payApi from '@/api/pay'
import util from '@/composables/util'
import { useToast } from 'vue-toast-notification'
import QrcodeVue from 'qrcode.vue'
import { fetchEventSource } from '@fortaine/fetch-event-source'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const $toast = useToast()

const getImageFile = util.getImageFile

const prop = defineProps({
  show: Boolean
})
const emit = defineEmits(['update:show'])

const abilityList = ['消息不限次数', '消息不限长度', '云从大模型随时体验']
const plans = [
  { name: '套餐一', planId: 1, amount: 1, tokens: '2万' },
  { name: '套餐二', planId: 2, amount: 25, tokens: '53万', extra_image: 'pay/extra-5.png' },
  { name: '套餐三', planId: 3, amount: 50, tokens: '110万', extra_image: 'pay/extra-10.png' },
  { name: '套餐四', planId: 4, amount: 100, tokens: '240万', extra_image: 'pay/extra-20.png' },
  { name: '套餐五', planId: 5, amount: 200, tokens: '560万', extra_image: 'pay/extra-40.png' },
  { name: '套餐六', planId: 6, amount: 98, period: '30天', periodUnit: '月' },
  { name: '套餐七', planId: 7, amount: 198, period: '120天', periodUnit: '月' },
  { name: '套餐八', planId: 8, amount: 498, period: '365天', periodUnit: '年' }
]

const data = reactive({
  planIndex: 0,
  showPayQrcode: false,
  qrcode: '',
  showPaySuccess: false
})

const currentPlan = computed(() => {
  return plans[data.planIndex]
})

const selectPlan = (index) => {
  data.planIndex = index
  data.showPayQrcode = false
}

const callPay = function () {
  console.log(currentPlan.value)
  payApi.wechatPayNative({ plan_id: currentPlan.value.planId }).then((res) => {
    data.qrcode = res.data.qrocde
    data.showPayQrcode = true
    $toast.success('请扫码支付')
  })
}

const closeAll = () => {
  data.showPaySuccess = false
  emit('update:show', false)
}

// 支付成通知
let ctrl
watch(
  () => prop.show,
  (newVal) => {
    if (newVal) {
      ctrl = new AbortController()
      fetchEventSource('/api/v1/notification/sse', {
        headers: {
          Authorization: 'Bearer ' + userStore.token
        },
        signal: ctrl.signal,
        onopen(response) {
          console.log(response)
        },
        onmessage(msg) {
          console.log(msg)
          switch (msg.event) {
            case 'heartbeat':
              break
            case 'pay_success':
              data.showPaySuccess = true
              break
          }
        },
        onclose() {
          console.log('EventSource close')
        },
        onerror(err) {
          throw err
        }
      })
    } else {
      ctrl.abort()
      console.log(ctrl)
    }
  }
)
</script>
