import { useUserStore } from '@/stores/user'

export default {
  getImageFile: (url) => {
    return new URL(`/images/${url}`, import.meta.url).href
  },
  redirectToChat: () => {
    const userStore = useUserStore()
    if (userStore.token) {
      window.open(`${import.meta.env.VITE_CHAT_HOST}?chat_token=${userStore.info.chat_token}`, '_blank')
      return true
    } else {
      return false
    }
  }
}
