<template>
  <div class="bg-grey text-black w-full min-h-screen">
    <RouterView />
  </div>
</template>

<script setup>
import { RouterView, useRouter } from 'vue-router'
import { onMounted } from 'vue'
import userApi from '@/api/user'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const router = useRouter()
if (screen.width <= 500) {
  router.push({ path: '/h5' })
}

onMounted(() => {
  if (userStore.token) {
    userApi.info().then((res) => {
      userStore.$patch({ info: res.data })
    })
  }
})
</script>

<style scoped></style>
